import React, { useState, useEffect } from "react"
import { Modal, Col, Row, Button, Container } from "react-bootstrap"
import graph1 from "../images/topic-1/step-1/graph-1.svg"
import graph2 from "../images/topic-1/step-1/graph-2.svg"
import graph3 from "../images/topic-1/step-1/graph-3.svg"
import graph1_fr from "../images/topic-1/step-1/graph-1_fr.svg"
import graph2_fr from "../images/topic-1/step-1/graph-2_fr.svg"
import graph3_fr from "../images/topic-1/step-1/graph-3_fr.svg"
import expert1 from "../images/topic-1/step-1/prof-barrios.png"
import expert2 from "../images/topic-1/step-1/prof-nielsen.png"
import expert3 from "../images/topic-1/step-1/prof-cho.png"
import parse from "html-react-parser"
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const ExpModal = props => {
  const { languages, originalPath, language } = useI18next()
  const { t } = useTranslation()
  const [tab, setTab] = useState(props.defaultPerson)
  // export default function modal(props) {

  // useEffect(() => {
  //   setTab(props.defaultPerson)
  //   console.log(props.defaultPerson)
  //   return () => {
  //     console.log("return", props.defaultPerson)
  //     setTab(props.defaultPerson)
  //   }
  // }, [props.defaultPerson])

  const pageContent = {
    experts: [
      {
        name: parse(t("Topic1_Step1_experts[0].name")),
        img: expert1,
        text: parse(t("Topic1_Step1_experts[0].text")),
      },
      {
        name: parse(t("Topic1_Step1_experts[1].name")),
        img: expert2,
        text: parse(t("Topic1_Step1_experts[1].text")),
      },
      {
        name: parse(t("Topic1_Step1_experts[2].name")),
        img: expert3,
        text: parse(t("Topic1_Step1_experts[2].text")),
      },
    ],
  }

  // =========================================================================================================
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Using Grid in Modal
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="show-grid">
        <Container>
          <div className="groupItemsOFF" style={{ padding: "30px" }}>
            <Row style={{ padding: "0px 100px" }}>
              <Col sm="6" md="4" lg="4" className="text-center">
                <div className="hoverMe">
                  <img
                    className={
                      props.defaultPerson == 0
                        ? "roundImg activeImg"
                        : "roundImg"
                    }
                    src={expert1}
                    alt={parse(t("Topic1_Step1_experts[0].alt"))}
                    style={{ width: "90%" }}
                    onClick={() => {
                      props.onTab(0)
                    }}
                  />
                  <h4>{pageContent.experts[0].name}</h4>
                </div>
              </Col>
              <Col sm="6" md="4" lg="4" className="text-center">
                <div className="hoverMe">
                  <img
                    className={
                      props.defaultPerson == 1
                        ? "roundImg activeImg"
                        : "roundImg"
                    }
                    src={expert2}
                    alt={parse(t("Topic1_Step1_experts[1].alt"))}
                    style={{ width: "90%" }}
                    onClick={() => {
                      props.onTab(1)
                    }}
                  />
                  <h4>{pageContent.experts[1].name}</h4>
                </div>
              </Col>
              <Col sm="6" md="4" lg="4" className="text-center">
                <div className="hoverMe">
                  <img
                    className={
                      props.defaultPerson == 2
                        ? "roundImg activeImg"
                        : "roundImg"
                    }
                    src={expert3}
                    alt={parse(t("Topic1_Step1_experts[2].alt"))}
                    style={{ width: "90%" }}
                    onClick={() => {
                      props.onTab(2)
                    }}
                  />
                  <h4>{pageContent.experts[2].name}</h4>
                </div>
              </Col>
            </Row>
            <br />
            <div>
              <Row hidden={props.defaultPerson != 0}>
                <Col md="6" lg="8">
                  {pageContent.experts[0].text}
                </Col>
                <Col md="6" lg="4">
                  <img
                    src={language == "en" ? graph1 : graph1_fr}
                    alt={parse(t("Topic1_Step1_experts[0].graphAlt"))}
                  />
                  <p className="smText">
                    {parse(t("Topic1_Step1_experts[0].graphText"))}
                  </p>
                </Col>
              </Row>
              <Row hidden={props.defaultPerson != 1}>
                <Col md="6" lg="8">
                  {pageContent.experts[1].text}
                </Col>
                <Col md="6" lg="4">
                  <img
                    src={language == "en" ? graph2 : graph2_fr}
                    alt={parse(t("Topic1_Step1_experts[1].graphAlt"))}
                  />
                  <p className="smText">
                    {parse(t("Topic1_Step1_experts[1].graphText"))}
                  </p>
                </Col>
              </Row>
              <Row hidden={props.defaultPerson != 2}>
                <Col md="6" lg="8">
                  {pageContent.experts[2].text}
                </Col>
                <Col md="6" lg="4">
                  <img
                    src={language == "en" ? graph3 : graph3_fr}
                    alt={parse(t("Topic1_Step1_experts[2].graphAlt"))}
                  />
                  <p className="smText">
                    {parse(t("Topic1_Step1_experts[2].graphText"))}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button className="closeBtn" onClick={props.onHide}>
          {parse(t("Close"))}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

// example:=======================================
// function App() {
//   const [modalShow, setModalShow] = useState(false)

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch modal with grid
//       </Button>

//       <MydModal show={modalShow} onHide={() => setModalShow(false)} />
//     </>
//   )
// }

export default ExpModal
