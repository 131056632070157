import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  Row,
  Col,
  Dropdown,
  Tooltip,
  OverlayTrigger,
  Form,
} from "react-bootstrap"
import {
  faQuestionCircle,
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ButtonLink from "../../components/buttonLink"
import musselgraph from "../../images/topic-1/step-3/graph-default.svg"
import musselgraph2 from "../../images/topic-1/step-3/graph-year-1.svg"
import musselgraph3 from "../../images/topic-1/step-3/graph-year-2.svg"
import musselgraph4 from "../../images/topic-1/step-3/graph-year-3.svg"
import musselgraph5 from "../../images/topic-1/step-3/graph-year-4.svg"
import musselgraph6 from "../../images/topic-1/step-3/graph-year-5.svg"
import musselgraph7 from "../../images/topic-1/step-3/graph-curve.svg"
import musselgraph_fr from "../../images/topic-1/step-3/graph-default_fr.svg"
import musselgraph2_fr from "../../images/topic-1/step-3/graph-year-1_fr.svg"
import musselgraph3_fr from "../../images/topic-1/step-3/graph-year-2_fr.svg"
import musselgraph4_fr from "../../images/topic-1/step-3/graph-year-3_fr.svg"
import musselgraph5_fr from "../../images/topic-1/step-3/graph-year-4_fr.svg"
import musselgraph6_fr from "../../images/topic-1/step-3/graph-year-5_fr.svg"
import musselgraph7_fr from "../../images/topic-1/step-3/graph-curve_fr.svg"
import expert1 from "../../images/topic-1/step-1/prof-barrios.png"
import expert2 from "../../images/topic-1/step-1/prof-nielsen.png"
import expert3 from "../../images/topic-1/step-1/prof-cho.png"
import equation1 from "../../images/equations/logistic-equation-1.svg"
import equation2 from "../../images/equations/logistic-equation-1b.svg"
import equation3 from "../../images/equations/logistic-equation-1c.svg"
import BottomNav from "../../components/bottomNav"
import MyBreadcrumb from "../../components/myBreadcrumb"
import MyModal from "../../components/expModal"
import RespMsg from "../../components/respMsg"
import Instructions from "../../components/instructions"
import RadioQuestion from "../../components/radioQuestion"
import parse from "html-react-parser"
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const ExpGrowthStep3 = ({ location }) => {
  const { languages, originalPath, language } = useI18next()
  const { t } = useTranslation()

  const renderTooltipInd = props => (
    <Tooltip id="button-tooltip" {...props}>
      {pageContent.tooltips[0]}
    </Tooltip>
  )
  const renderTooltipDep = props => (
    <Tooltip id="button-tooltip" {...props}>
      {pageContent.tooltips[1]}
    </Tooltip>
  )

  // =========================================================================================================
  const pageContent = {
    pageTitle: parse(t("Topic1_Step3_pageTitle")),
    breadcrumbs: [
      parse(t("Topic1_Step3_breadcrumbs.currentTitle[0]")),
      parse(t("Topic1_Step3_breadcrumbs.currentTitle[1]")),
      parse(t("Topic1_Step3_breadcrumbs.currentTitle[2]")),
      parse(t("Topic1_Step3_breadcrumbs.currentTitle[3]")),
    ],
    title: parse(t("Topic1_Step3_title")),
    topText: parse(t("Topic1_Step3_topText")),
    instructions: [
      parse(t("Topic1_Step3_instructions[0]")),
      parse(t("Topic1_Step3_instructions[1]")),
      parse(t("Topic1_Step3_instructions[2]")),
      parse(t("Topic1_Step3_instructions[3]")),
    ],
    tooltips: [
      parse(t("Topic1_Step3_tooltips[0]")),
      parse(t("Topic1_Step3_tooltips[1]")),
    ],
    //LABEL PAGE
    mainInfo: [
      parse(t("Topic1_Step3_mainInfo[0]")),
      [
        parse(t("Topic1_Step3_mainInfo[1][0]")),
        parse(t("Topic1_Step3_mainInfo[1][1]")),
        parse(t("Topic1_Step3_mainInfo[1][2]")),
        parse(t("Topic1_Step3_mainInfo[1][3]")),
        parse(t("Topic1_Step3_mainInfo[1][4]")),
      ],
      parse(t("Topic1_Step3_mainInfo[2]")),
      parse(t("Topic1_Step3_mainInfo[3]")),
      parse(t("Topic1_Step3_mainInfo[4]")),
      parse(t("Topic1_Step3_mainInfo[5]")),
      parse(t("Topic1_Step3_mainInfo[6]")),
      parse(t("Topic1_Step3_mainInfo[7]")),
    ],
    tables: {
      indDepTitles: {
        headers: [
          parse(t("Topic1_Step3_tables.indDepTitles.headers[0]")),
          parse(t("Topic1_Step3_tables.indDepTitles.headers[1]")),
          parse(t("Topic1_Step3_tables.indDepTitles.headers[2]")),
          parse(t("Topic1_Step3_tables.indDepTitles.headers[3]")),
          parse(t("Topic1_Step3_tables.indDepTitles.headers[4]")),
        ],
        data: [
          parse(t("Topic1_Step3_tables.indDepTitles.data[0]")),
          parse(t("Topic1_Step3_tables.indDepTitles.data[1]")),
          parse(t("Topic1_Step3_tables.indDepTitles.data[2]")),
          parse(t("Topic1_Step3_tables.indDepTitles.data[3]")),
          parse(t("Topic1_Step3_tables.indDepTitles.data[4]")),
          "0 to 5",
          "0 to 3",
          "0 to 70,000",
        ],
      },
      yourData: {
        headers: [
          parse(t("Topic1_Step3_tables.yourData.haeders[0]")),
          parse(t("Topic1_Step3_tables.yourData.haeders[1]")),
          parse(t("Topic1_Step3_tables.yourData.haeders[2]")),
          parse(t("Topic1_Step3_tables.yourData.haeders[3]")),
          parse(t("Topic1_Step3_tables.yourData.haeders[4]")),
          "1",
          "2",
          "3",
          "4",
          "5",
        ],
        data: [
          // 0
          ["0", "0", "1"],
          // 1
          ["9", "8", "3"],
          // 2
          ["183", "126", "90"],
          // 3
          ["3,380", "2,765", "1,869"],
          // 4
          ["68,274", "58,329", "34,524"],
        ],
      },
    },
    msgBox: [
      parse(t("Topic1_Step3_msgBox[0]")),
      parse(t("Topic1_Step3_msgBox[1]")),
    ],
    radios: {
      text: [
        parse(t("Topic1_Step3_radios.text[0]")),
        parse(t("Topic1_Step3_radios.text[1]")),
        parse(t("Topic1_Step3_radios.text[2]")),
      ],
      msg: [
        //0
        <p style={{ color: "red" }}>
          <FontAwesomeIcon icon={faTimesCircle} />
          {parse(t("Topic1_Step3_radios.msg[0][0]"))}
          <span
            className="linkMeNoColor"
            onClick={() =>
              setModalShow({
                ...modalShow,
                defaultPerson: 2,
                show: true,
              })
            }
          >
            {parse(t("Topic1_Step3_radios.msg[0][1]"))}
          </span>
          {parse(t("Topic1_Step3_radios.msg[0][2]"))}
        </p>,
        //1
        <p style={{ color: "red" }}>
          <FontAwesomeIcon icon={faTimesCircle} />{" "}
          {parse(t("Topic1_Step3_radios.msg[1][0]"))}
          <span
            className="linkMeNoColor"
            onClick={() =>
              setModalShow({
                ...modalShow,
                defaultPerson: 1,
                show: true,
              })
            }
          >
            {parse(t("Topic1_Step3_radios.msg[1][1]"))}
          </span>
          {parse(t("Topic1_Step3_radios.msg[1][2]"))}
        </p>,
        //2
        <p style={{ color: "green" }}>
          <FontAwesomeIcon icon={faCheckCircle} />
          {parse(t("Topic1_Step3_radios.msg[2][0]"))}
          <span
            className="linkMeNoColor"
            onClick={() =>
              setModalShow({
                ...modalShow,
                defaultPerson: 0,
                show: true,
              })
            }
          >
            {parse(t("Topic1_Step3_radios.msg[2][1]"))}
          </span>
          {parse(t("Topic1_Step3_radios.msg[2][2]"))}
        </p>,
        // EQUATION RADIOS=======================================================
        //3
        <p style={{ color: "red" }}>
          <FontAwesomeIcon icon={faTimesCircle} />
          {parse(t("Topic1_Step3_radios.msg[3][0]"))}
          <span
            className="linkMeNoColor"
            onClick={() =>
              setModalShow({
                ...modalShow,
                defaultPerson: 2,
                show: true,
              })
            }
          >
            {parse(t("Topic1_Step3_radios.msg[3][1]"))}
          </span>
          {parse(t("Topic1_Step3_radios.msg[3][2]"))}
        </p>,
        //4
        <p style={{ color: "red" }}>
          <FontAwesomeIcon icon={faTimesCircle} />
          {parse(t("Topic1_Step3_radios.msg[4][0]"))}
          <span
            className="linkMeNoColor"
            onClick={() =>
              setModalShow({
                ...modalShow,
                defaultPerson: 1,
                show: true,
              })
            }
          >
            {parse(t("Topic1_Step3_radios.msg[4][1]"))}
          </span>
          {parse(t("Topic1_Step3_radios.msg[4][2]"))}
        </p>,
        //5
        <p style={{ color: "green" }}>
          <FontAwesomeIcon icon={faCheckCircle} />
          {parse(t("Topic1_Step3_radios.msg[5][0]"))}
          <span
            className="linkMeNoColor"
            onClick={() =>
              setModalShow({
                ...modalShow,
                defaultPerson: 0,
                show: true,
              })
            }
          >
            {parse(t("Topic1_Step3_radios.msg[5][1]"))}
          </span>
          {parse(t("Topic1_Step3_radios.msg[5][2]"))}
        </p>,
      ],
    },
    images: {
      graphs: [
        {
          src: language == "en" ? musselgraph : musselgraph_fr,
          alt: parse(t("Topic1_Step3_radios.images.graphs[0].alt")),
        },
        {
          src: language == "en" ? musselgraph2 : musselgraph2_fr,
          alt: parse(t("Topic1_Step3_radios.images.graphs[1].alt")),
        },
        {
          src: language == "en" ? musselgraph3 : musselgraph3_fr,
          alt: parse(t("Topic1_Step3_radios.images.graphs[2].alt")),
        },
        {
          src: language == "en" ? musselgraph4 : musselgraph4_fr,
          alt: parse(t("Topic1_Step3_radios.images.graphs[3].alt")),
        },
        {
          src: language == "en" ? musselgraph5 : musselgraph5_fr,
          alt: parse(t("Topic1_Step3_radios.images.graphs[4].alt")),
        },
        {
          src: language == "en" ? musselgraph6 : musselgraph6_fr,
          alt: parse(t("Topic1_Step3_radios.images.graphs[5].alt")),
        },
        {
          src: language == "en" ? musselgraph7 : musselgraph7_fr,
          alt: parse(t("Topic1_Step3_radios.images.graphs[6].alt")),
        },
      ],
      experts: [
        {
          src: expert1,
          alt: parse(t("Topic1_Step3_radios.images.experts[0].alt")),
        },
        {
          src: expert2,
          alt: parse(t("Topic1_Step3_radios.images.experts[1].alt")),
        },
        {
          src: expert3,
          alt: parse(t("Topic1_Step3_radios.images.experts[2].alt")),
        },
      ],
      equations: [
        {
          src: equation1,
          alt: parse(t("Topic1_Step3_radios.images.equations[0].alt")),
        },
        {
          src: equation2,
          alt: parse(t("Topic1_Step3_radios.images.equations[1].alt")),
        },
        {
          src: equation3,
          alt: parse(t("Topic1_Step3_radios.images.equations[2].alt")),
        },
      ],
    },
    bottomText: [parse(t("Topic1_Step3_bottomText"))],
  }

  const [modalShow, setModalShow] = useState({ defaultPerson: 1, show: false })
  const [errorConst, setErrorConst] = useState(false)
  const [errorExtF, setErrorExtF] = useState(false)
  const [error2Const, setError2Const] = useState(false)
  const [error2ExtF, setError2ExtF] = useState(false)
  const [showLastQ, setShowLastQ] = useState(false)
  const [showLastQ2, setShowLastQ2] = useState(false)
  const [currentPage, setCurrentPage] = useState(pageContent.breadcrumbs[0])
  const [showCont, setShowCont] = useState(false)
  const [msgType, setMsgType] = useState("")
  const [msgContent, setMsgContent] = useState("")
  const [showMsg, setShowMsg] = useState(false)
  const [graphYear, setGraphYear] = useState(0)
  const [showRadioCheck, setShowRadioCheck] = useState(true)
  const [showRadio2Check, setShowRadio2Check] = useState(true)

  // DropDown Menus===================================
  const [indVariable, setIndVariable] = useState({
    variable: pageContent.tables.indDepTitles.data[0],
    units: pageContent.tables.indDepTitles.data[2],
    scale: pageContent.tables.indDepTitles.data[5],
  })
  const [depVariable, setDepVariable] = useState({
    variable: pageContent.tables.indDepTitles.data[0],
    units: pageContent.tables.indDepTitles.data[2],
    scale: pageContent.tables.indDepTitles.data[5],
  })

  const onSubmit = () => {
    if (
      (indVariable.variable == "t, Time" &&
        depVariable.variable == "N, Pop. Size" &&
        indVariable.units == "Years" &&
        depVariable.units == "ZM/m2" &&
        indVariable.scale == pageContent.tables.indDepTitles.data[5] &&
        depVariable.scale == pageContent.tables.indDepTitles.data[7]) |
      (indVariable.variable == "t, Temps" &&
        depVariable.variable == "N, Taille de la population" &&
        indVariable.units == "Années" &&
        depVariable.units == "ZM/m2" &&
        indVariable.scale == pageContent.tables.indDepTitles.data[5] &&
        depVariable.scale == pageContent.tables.indDepTitles.data[7])
      // indVariable.variable == pageContent.tables.indDepTitles.data[1] &&
      // depVariable.variable == pageContent.tables.indDepTitles.data[0] &&
      // indVariable.units == pageContent.tables.indDepTitles.data[3] &&
      // depVariable.units == pageContent.tables.indDepTitles.data[4] &&
      // indVariable.scale == pageContent.tables.indDepTitles.data[5] &&
      // depVariable.scale == pageContent.tables.indDepTitles.data[7]
    ) {
      setMsgType("correct")
      setMsgContent(pageContent.msgBox[1])
      setShowCont(true)
    } else {
      setMsgType("error")
      setMsgContent(pageContent.msgBox[0])
      setShowCont(false)
    }
    setShowMsg(true)
  }
  const [showNextQ, setShowNextQ] = useState(false)
  const showNextQFun = () => {
    setShowNextQ(true)
  }
  // ================================================================================================
  return (
    <Layout>
      <SEO title={pageContent.pageTitle} />
      <MyBreadcrumb
        topicLink="exponentialgrowth"
        topicTitle={parse(t("Topic1_Step2_breadcrumbs.topicTitle"))}
        secondTitle=""
        currentTitle={currentPage}
      />
      <div className="homeContent">
        {/* STEP 3A============================================== */}
        <div hidden={currentPage != pageContent.breadcrumbs[0]}>
          <Row>
            <Col sm="12">
              {" "}
              <h3 className="blueTitleOFF">{pageContent.title}</h3>
              <p>{pageContent.topText}</p>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <h4>{pageContent.mainInfo[0]}</h4>
              <span>
                <p>
                  <p>{parse(t("Topic1_Step3_mainInfo[1][0]"))}</p>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipInd}
                  >
                    <span className="popup bold">
                      {parse(t("Topic1_Step3_mainInfo[1][1]"))}
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </span>
                  </OverlayTrigger>{" "}
                  {parse(t("Topic1_Step3_mainInfo[1][2]"))}
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipDep}
                  >
                    <span className="popup bold">
                      {parse(t("Topic1_Step3_mainInfo[1][3]"))}
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </span>
                  </OverlayTrigger>{" "}
                  {parse(t("Topic1_Step3_mainInfo[1][4]"))}
                </p>
              </span>
            </Col>
            {/* <Col md="6">
              <div style={{ marginBottom: `1.45rem` }}>
                <img src={musselgraph} />
              </div>
            </Col> */}
          </Row>
          <Instructions content={pageContent.instructions[0]} />
          <Row>
            <Col md="6">
              <table>
                <tr>
                  <th>{pageContent.tables.indDepTitles.headers[0]}</th>
                  <th>{pageContent.tables.indDepTitles.headers[1]}</th>
                </tr>{" "}
                <tr>
                  <th>{pageContent.tables.indDepTitles.headers[2]}</th>
                  <th>{pageContent.tables.indDepTitles.headers[2]}</th>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="muted"
                        id="dropdown-basic"
                        className="selectBox"
                      >
                        {indVariable.variable}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onSelect={(eventKey, event) => {
                            setIndVariable({
                              ...indVariable,
                              variable: pageContent.tables.indDepTitles.data[0],
                            })
                          }}
                        >
                          {pageContent.tables.indDepTitles.data[0]}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={(eventKey, event) => {
                            setIndVariable({
                              ...indVariable,
                              variable: pageContent.tables.indDepTitles.data[1],
                            })
                          }}
                        >
                          {pageContent.tables.indDepTitles.data[1]}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* <select
                      onChange={e => this.setState({ value: e.target.value })}
                    >
                      <option value="nPopSize">N, Pop. Size</option>
                      <option value="tTime">t, time</option>
                    </select> */}
                  </td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="muted"
                        id="dropdown-basic"
                        className="selectBox"
                      >
                        {depVariable.variable}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onSelect={(eventKey, event) => {
                            setDepVariable({
                              ...depVariable,
                              variable: pageContent.tables.indDepTitles.data[0],
                            })
                          }}
                        >
                          {pageContent.tables.indDepTitles.data[0]}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={(eventKey, event) => {
                            setDepVariable({
                              ...depVariable,
                              variable: pageContent.tables.indDepTitles.data[1],
                            })
                          }}
                        >
                          {pageContent.tables.indDepTitles.data[1]}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* {" "}
                    <select name="cars" id="cars">
                      <option value="volvo">N, Pop. Size</option>
                      <option value="saab">t, time</option>
                    </select> */}
                  </td>
                </tr>
                <tr>
                  <th>{pageContent.tables.indDepTitles.headers[3]}</th>
                  <th>{pageContent.tables.indDepTitles.headers[3]}</th>
                </tr>
                <tr>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="muted"
                        id="dropdown-basic"
                        className="selectBox"
                      >
                        {indVariable.units}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onSelect={(eventKey, event) => {
                            setIndVariable({
                              ...indVariable,
                              units: pageContent.tables.indDepTitles.data[2],
                            })
                          }}
                        >
                          {pageContent.tables.indDepTitles.data[2]}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={(eventKey, event) => {
                            setIndVariable({
                              ...indVariable,
                              units: pageContent.tables.indDepTitles.data[3],
                            })
                          }}
                        >
                          {pageContent.tables.indDepTitles.data[3]}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={(eventKey, event) => {
                            setIndVariable({
                              ...indVariable,
                              units: pageContent.tables.indDepTitles.data[4],
                            })
                          }}
                        >
                          {pageContent.tables.indDepTitles.data[4]}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="muted"
                        id="dropdown-basic"
                        className="selectBox"
                      >
                        {depVariable.units}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onSelect={(eventKey, event) => {
                            setDepVariable({
                              ...depVariable,
                              units: pageContent.tables.indDepTitles.data[2],
                            })
                          }}
                        >
                          {pageContent.tables.indDepTitles.data[2]}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={(eventKey, event) => {
                            setDepVariable({
                              ...depVariable,
                              units: pageContent.tables.indDepTitles.data[3],
                            })
                          }}
                        >
                          {pageContent.tables.indDepTitles.data[3]}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={(eventKey, event) => {
                            setDepVariable({
                              ...depVariable,
                              units: pageContent.tables.indDepTitles.data[4],
                            })
                          }}
                        >
                          {pageContent.tables.indDepTitles.data[4]}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <th>{pageContent.tables.indDepTitles.headers[4]}</th>
                  <th>{pageContent.tables.indDepTitles.headers[4]}</th>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="muted"
                        id="dropdown-basic"
                        className="selectBox"
                      >
                        {indVariable.scale}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onSelect={(eventKey, event) => {
                            setIndVariable({
                              ...indVariable,
                              scale: pageContent.tables.indDepTitles.data[5],
                            })
                          }}
                        >
                          {pageContent.tables.indDepTitles.data[5]}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={(eventKey, event) => {
                            setIndVariable({
                              ...indVariable,
                              scale: pageContent.tables.indDepTitles.data[6],
                            })
                          }}
                        >
                          {pageContent.tables.indDepTitles.data[6]}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={(eventKey, event) => {
                            setIndVariable({
                              ...indVariable,
                              scale: pageContent.tables.indDepTitles.data[7],
                            })
                          }}
                        >
                          {pageContent.tables.indDepTitles.data[7]}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="muted"
                        id="dropdown-basic"
                        className="selectBox"
                      >
                        {depVariable.scale}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onSelect={(eventKey, event) => {
                            setDepVariable({
                              ...depVariable,
                              scale: pageContent.tables.indDepTitles.data[5],
                            })
                          }}
                        >
                          {pageContent.tables.indDepTitles.data[5]}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={(eventKey, event) => {
                            setDepVariable({
                              ...depVariable,
                              scale: pageContent.tables.indDepTitles.data[6],
                            })
                          }}
                        >
                          {pageContent.tables.indDepTitles.data[6]}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={(eventKey, event) => {
                            setDepVariable({
                              ...depVariable,
                              scale: pageContent.tables.indDepTitles.data[7],
                            })
                          }}
                        >
                          {pageContent.tables.indDepTitles.data[7]}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              </table>
              <ButtonLink
                onClick={onSubmit}
                btnText={parse(t("Submit Results"))}
                // btnColor="grey"
                className="btnNext"
                disabled={showCont}
              />
              <br /> <br />
            </Col>
            <br />
            <Col md="6">
              <h5 className="bold" style={{ marginTop: "10px" }}>
                {pageContent.tables.yourData.headers[0]}
              </h5>{" "}
              <table className="alignRightTable">
                <tr>
                  <th>{pageContent.tables.yourData.headers[1]}</th>
                  <th>{pageContent.tables.yourData.headers[2]}</th>
                  <th>{pageContent.tables.yourData.headers[3]}</th>
                  <th>{pageContent.tables.yourData.headers[4]}</th>
                </tr>
                <tr className="heightMatch">
                  <td>{pageContent.tables.yourData.headers[5]}</td>
                  <td>{pageContent.tables.yourData.data[0][0]}</td>
                  <td>{pageContent.tables.yourData.data[0][1]}</td>
                  <td>{pageContent.tables.yourData.data[0][2]}</td>
                </tr>
                <tr>
                  <td>{pageContent.tables.yourData.headers[6]}</td>
                  <td>{pageContent.tables.yourData.data[1][0]}</td>
                  <td>{pageContent.tables.yourData.data[1][1]}</td>
                  <td>{pageContent.tables.yourData.data[1][2]}</td>
                </tr>
                <tr className="heightMatch">
                  <td>{pageContent.tables.yourData.headers[7]}</td>
                  <td>{pageContent.tables.yourData.data[2][0]}</td>
                  <td>{pageContent.tables.yourData.data[2][1]}</td>
                  <td>{pageContent.tables.yourData.data[2][2]}</td>
                </tr>{" "}
                <tr>
                  <td>{pageContent.tables.yourData.headers[8]}</td>
                  <td>{pageContent.tables.yourData.data[3][0]}</td>
                  <td>{pageContent.tables.yourData.data[3][1]}</td>
                  <td>{pageContent.tables.yourData.data[3][2]}</td>
                </tr>{" "}
                <tr className="heightMatch">
                  <td>{pageContent.tables.yourData.headers[9]}</td>
                  <td>{pageContent.tables.yourData.data[4][0]}</td>
                  <td>{pageContent.tables.yourData.data[4][1]}</td>
                  <td>{pageContent.tables.yourData.data[4][2]}</td>
                </tr>
              </table>
            </Col>
          </Row>
          <div hidden={!showMsg}>
            <RespMsg type={msgType} content={msgContent} />
          </div>
        </div>

        {/* STEP 3B======================================================= */}
        <div hidden={currentPage != pageContent.breadcrumbs[1]}>
          <Row>
            <Col sm="12">
              {" "}
              <h3 className="blueTitleOFF">{pageContent.title}</h3>
              <h4>{pageContent.mainInfo[2]}</h4>
              <Instructions content={pageContent.instructions[1]} />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <h5 className="bold" style={{ marginTop: "10px" }}>
                {pageContent.tables.yourData.headers[0]}
              </h5>{" "}
              <table className="alignRightTable">
                <tr>
                  <th>{pageContent.tables.yourData.headers[1]}</th>
                  <th>{pageContent.tables.yourData.headers[2]}</th>
                  <th>{pageContent.tables.yourData.headers[3]}</th>
                  <th>{pageContent.tables.yourData.headers[4]}</th>
                </tr>
                <tr
                  className={
                    graphYear == 1 ? "heightMatch highlightYear" : "heightMatch"
                  }
                >
                  <td>{pageContent.tables.yourData.headers[5]}</td>
                  <td>{pageContent.tables.yourData.data[0][0]}</td>
                  <td>{pageContent.tables.yourData.data[0][1]}</td>
                  <td>{pageContent.tables.yourData.data[0][2]}</td>
                </tr>
                <tr className={graphYear == 2 ? "highlightYear" : ""}>
                  <td>{pageContent.tables.yourData.headers[6]}</td>
                  <td>{pageContent.tables.yourData.data[1][0]}</td>
                  <td>{pageContent.tables.yourData.data[1][1]}</td>
                  <td>{pageContent.tables.yourData.data[1][2]}</td>
                </tr>
                <tr
                  className={
                    graphYear == 3 ? "heightMatch highlightYear" : "heightMatch"
                  }
                >
                  <td>{pageContent.tables.yourData.headers[7]}</td>
                  <td>{pageContent.tables.yourData.data[2][0]}</td>
                  <td>{pageContent.tables.yourData.data[2][1]}</td>
                  <td>{pageContent.tables.yourData.data[2][2]}</td>
                </tr>{" "}
                <tr className={graphYear == 4 ? "highlightYear" : ""}>
                  <td>{pageContent.tables.yourData.headers[8]}</td>
                  <td>{pageContent.tables.yourData.data[3][0]}</td>
                  <td>{pageContent.tables.yourData.data[3][1]}</td>
                  <td>{pageContent.tables.yourData.data[3][2]}</td>
                </tr>{" "}
                <tr
                  className={
                    graphYear == 5 ? "heightMatch highlightYear" : "heightMatch"
                  }
                >
                  <td>{pageContent.tables.yourData.headers[9]}</td>
                  <td>{pageContent.tables.yourData.data[4][0]}</td>
                  <td>{pageContent.tables.yourData.data[4][1]}</td>
                  <td>{pageContent.tables.yourData.data[4][2]}</td>
                </tr>
              </table>
            </Col>
            <Col md="6">
              <div style={{ marginBottom: `1.45rem` }}>
                <img
                  src={pageContent.images.graphs[graphYear].src}
                  alt={pageContent.images.graphs[graphYear].alt}
                />
                <p className="smText">
                  {parse(t("Topic1_Step3_radios.images.graphs.caption"))}
                </p>

                <ButtonLink
                  onClick={() => setGraphYear(graphYear + 1)}
                  btnText={
                    <span>{parse(t("Plot Data")) + (graphYear + 1)})</span>
                  }
                  // btnColor="grey"
                  className="btnNext"
                  hidden={graphYear >= 5}
                />
                <ButtonLink
                  onClick={() => setGraphYear(graphYear + 1)}
                  btnText={parse(t("Draw Line"))}
                  // btnColor="grey"
                  className="btnNext"
                  hidden={(graphYear < 5) | (graphYear > 5)}
                  disabled={graphYear != 5}
                />
              </div>
            </Col>
          </Row>
        </div>

        {/* STEP 3C======================================= */}
        <div hidden={currentPage != pageContent.breadcrumbs[2]}>
          <Row>
            <Col sm="12">
              {" "}
              <h3 className="blueTitleOFF">{pageContent.title}</h3>
              <h4>{pageContent.mainInfo[3]}</h4>
              <Instructions content={pageContent.instructions[2]} />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <p className="bold">{pageContent.mainInfo[4]}</p>

              <Form>
                <RadioQuestion
                  data={[
                    {
                      id: "radio1",
                      name: "formRadios",
                      label: pageContent.radios.text[0],
                      answer: false,
                      checked: showRadioCheck == false ? 0 : null,
                    },
                    {
                      id: "radio2",
                      name: "formRadios",
                      label: pageContent.radios.text[1],
                      answer: true,
                      checked: showRadioCheck == false ? 0 : null,
                    },
                    {
                      id: "radio3",
                      name: "formRadios",
                      label: pageContent.radios.text[2],
                      answer: false,
                      checked: showRadioCheck == false ? 0 : null,
                    },
                  ]}
                  myOnClick={(answer, label, id) => {
                    switch (id) {
                      case "radio1":
                        setErrorConst(true)
                        setErrorExtF(false)
                        break
                      case "radio2":
                        setErrorConst(false)
                        setErrorExtF(false)
                        setShowLastQ(true)
                        break
                      case "radio3":
                        setErrorConst(false)
                        setErrorExtF(true)
                        break
                      default:
                        return null
                    }
                  }}
                  disabled={showLastQ}
                />
              </Form>
            </Col>
            <Col md="6">
              <div style={{ marginBottom: `1.45rem` }}>
                <img
                  src={pageContent.images.graphs[6].src}
                  alt={pageContent.images.graphs[6].alt}
                />
                <p className="smText">
                  {parse(t("Topic1_Step3_radios.images.graphs.caption"))}
                </p>
              </div>
            </Col>
          </Row>
          <div className="borderMeError" hidden={!errorConst}>
            <Row>
              <Col md="2">
                <img
                  src={pageContent.images.experts[2].src}
                  alt={pageContent.images.experts[2].alt}
                />
              </Col>
              <Col md="10">{pageContent.radios.msg[0]}</Col>
            </Row>
          </div>
          <div className="borderMeError" hidden={!errorExtF}>
            <Row>
              <Col md="2">
                <img
                  src={pageContent.images.experts[1].src}
                  alt={pageContent.images.experts[1].alt}
                />
              </Col>
              <Col md="10">{pageContent.radios.msg[1]}</Col>
            </Row>
          </div>
          <div className="borderMeCorrect" hidden={!showLastQ}>
            <Row>
              <Col md="2">
                <img
                  src={pageContent.images.experts[0].src}
                  alt={pageContent.images.experts[0].alt}
                />
              </Col>
              <Col md="10">{pageContent.radios.msg[2]}</Col>
            </Row>
          </div>
          <MyModal
            size="xl"
            className="rModal"
            defaultPerson={modalShow.defaultPerson}
            show={modalShow.show}
            onTab={tab => {
              setModalShow({ ...modalShow, defaultPerson: tab })
            }}
            onHide={() => setModalShow({ ...modalShow, show: false })}
            centered
          />
        </div>

        {/* STEP 3D======================================== */}
        <div hidden={currentPage != pageContent.breadcrumbs[3]}>
          <Row>
            <Col sm="12">
              <h3 className="blueTitleOFF">{pageContent.title}</h3>
              <h4>{pageContent.mainInfo[5]}</h4>
              <p>{pageContent.mainInfo[6]}</p>
              <Instructions content={pageContent.instructions[3]} />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <p className="bold">{pageContent.mainInfo[7]}</p>
              <Form>
                <RadioQuestion
                  data={[
                    {
                      id: "radio4",
                      name: "formRadios2",
                      label: (
                        <img
                          src={pageContent.images.equations[0].src}
                          alt={pageContent.images.equations[0].alt}
                          height="50px"
                        />
                      ),
                      answer: false,
                      checked: showRadio2Check == false ? 0 : null,
                    },
                    {
                      id: "radio5",
                      name: "formRadios2",
                      label: (
                        <img
                          src={pageContent.images.equations[1].src}
                          alt={pageContent.images.equations[1].alt}
                          height="50px"
                        />
                      ),
                      answer: true,
                      checked: showRadio2Check == false ? 0 : null,
                    },
                    {
                      id: "radio6",
                      name: "formRadios2",
                      label: (
                        <img
                          src={pageContent.images.equations[2].src}
                          alt={pageContent.images.equations[2].alt}
                          height="50px"
                        />
                      ),
                      answer: false,
                      checked: showRadio2Check == false ? 0 : null,
                    },
                  ]}
                  myOnClick={(answer, label, id) => {
                    switch (id) {
                      case "radio4":
                        setError2Const(false)
                        setError2ExtF(true)
                        break
                      case "radio5":
                        setError2Const(false)
                        setError2ExtF(false)
                        setShowLastQ2(true)
                        break
                      case "radio6":
                        setError2Const(true)
                        setError2ExtF(false)
                        break
                      default:
                        return null
                    }
                  }}
                  disabled={showLastQ2}
                />
              </Form>
            </Col>
            <Col md="6">
              <div style={{ marginBottom: `1.45rem` }}>
                <img
                  src={pageContent.images.graphs[6].src}
                  alt={pageContent.images.graphs[6].alt}
                />
                <p className="smText">
                  {parse(t("Topic1_Step3_radios.images.graphs.caption"))}
                </p>
              </div>
            </Col>
          </Row>
          <div className="borderMeError" hidden={!error2Const}>
            <Row>
              <Col md="2">
                <img
                  src={pageContent.images.experts[2].src}
                  alt={pageContent.images.experts[2].alt}
                />
              </Col>
              <Col md="10">{pageContent.radios.msg[3]}</Col>
            </Row>
          </div>
          <div className="borderMeError" hidden={!error2ExtF}>
            <Row>
              <Col md="2">
                <img
                  src={pageContent.images.experts[1].src}
                  alt={pageContent.images.experts[1].alt}
                />
              </Col>
              <Col md="10">{pageContent.radios.msg[4]}</Col>
            </Row>
          </div>
          <div className="borderMeCorrect" hidden={!showLastQ2}>
            <Row>
              <Col md="2">
                <img
                  src={pageContent.images.experts[0].src}
                  alt={pageContent.images.experts[0].alt}
                />
              </Col>
              <Col md="10">{pageContent.radios.msg[5]}</Col>
            </Row>
          </div>
        </div>

        <Row>
          <Col sm="12" md="12" className="alignRight">
            <hr />
            <BottomNav
              // btnLinkPrev="/exponentialgrowth/step2"
              prevName={parse(t("Previous"))}
              btnLinkPrev={
                currentPage == pageContent.breadcrumbs[0]
                  ? "/exponentialgrowth/step2"
                  : "#myHeader"
              }
              onClickPrev={() => {
                switch (currentPage) {
                  case "Step 3b: Plot the Data":
                    setCurrentPage(pageContent.breadcrumbs[0])
                    setGraphYear(0)
                    break
                  case "Step 3c: Fit your Data":
                    setCurrentPage(pageContent.breadcrumbs[1])
                    setShowRadioCheck(false)
                    setErrorConst(false)
                    setErrorExtF(false)
                    setShowLastQ(false)
                    break
                  case "Step 3d: Fit your Data (Equations)":
                    setCurrentPage(pageContent.breadcrumbs[2])
                    setError2Const(false)
                    setError2ExtF(false)
                    setShowRadioCheck(true)
                    setShowRadio2Check(false)
                    setShowLastQ2(false)
                    break
                  default:
                    return null
                }
              }}
              btnLinkNext={showLastQ2 ? "/exponentialgrowth/step4" : null}
              nextName={parse(t("Next"))}
              onClickNext={() => {
                if (showCont) {
                  // "Step 3a: Collect Data"
                  setCurrentPage(pageContent.breadcrumbs[1])
                  setShowCont(false)
                  setMsgContent("")
                  setMsgType("")
                  setShowMsg(false)
                  setIndVariable({
                    ...indVariable,
                    variable: pageContent.tables.indDepTitles.data[0],
                    units: pageContent.tables.indDepTitles.data[2],
                    scale: pageContent.tables.indDepTitles.data[5],
                  })
                  setDepVariable({
                    ...depVariable,
                    variable: pageContent.tables.indDepTitles.data[0],
                    units: pageContent.tables.indDepTitles.data[2],
                    scale: pageContent.tables.indDepTitles.data[5],
                  })
                }
                if (graphYear == 6) {
                  // "Step 3b: Plot the Data"
                  setCurrentPage(pageContent.breadcrumbs[2])
                  setGraphYear(0)
                  setShowRadioCheck(true)
                }
                if (showLastQ) {
                  // "Step 3c: Fit your Data"
                  setCurrentPage(pageContent.breadcrumbs[3])
                  setShowLastQ(0)
                  setShowRadioCheck(false)
                  setErrorConst(false)
                  setErrorExtF(false)
                  setShowLastQ(false)
                  setShowRadio2Check(true)
                }
              }}
              disabled={
                !showCont && graphYear != 6 && showLastQ != true && !showLastQ2
              }
            />
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default ExpGrowthStep3
