import React from "react"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"

const BottomNav = ({
  btnLinkPrev,
  btnLinkNext,
  disabled,
  hidden,
  prevName,
  nextName,
  onClickNext,
  onClickPrev,
  hiddenPrev,
}) => {
  return (
    <div>
      <Link to={btnLinkPrev}>
        <Button
          variant="secondary"
          className="btnPrev"
          onClick={onClickPrev}
          hidden={hiddenPrev}
        >
          {prevName}
        </Button>
      </Link>
      <Link to={btnLinkNext}>
        <Button
          variant="secondary"
          className="btnNext"
          disabled={disabled}
          hidden={hidden}
          onClick={onClickNext}
        >
          {nextName}
        </Button>
      </Link>
    </div>
  )
}

export default BottomNav
